import React from "react";
import "../Assets/css/case.css";
import "../Assets/css/base.css";
import "../Assets/css/demo1.css";
import "../Assets/css/home.css";
import Parallax from "react-rellax";
import $ from "jquery";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";

class IndexPage extends React.Component {
  componentDidMount() {
    require("paroller.js");
    $(document).ready(function() {
      $(".paroller-example").paroller({
        factor: 0.15,
        type: "foreground",
        direction: "vertical"
      });
    });
  }

  render() {
    return (
      <div>
        <SEO
          title="Makook Studio · We Design, Code and damn good at Cinematography "
          description="Makook is a a creative lab where multiple disciplines
                    collide. We want to make the world more acces­sible with
                    sus­tain­a­ble, func­tion­al and delightful experiences."
          image={
            "https://makook.space" + require("../images/Makook_Studio.jpg")
          }
        />
        <Header />

        <div
          data-post-title="makookcase"
          data-theme="theme-dark"
          className="theme-dark"
        >
          <main className="case makookcase">
            <h1 className="master-title">Studio</h1>

            <div
              className="background-color-makookcase-1 color-transition"
              data-color="#000"
            >
              <section className="image-block sticky-title-padding-top mobile-hero no-margin-bottom sticky-stop">
                <figure>
                  <Img fluid={this.props.data.imageOne.childImageSharp.fluid} />
                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className=" desktop-only">
                      Simply Not your Average studio
                    </p>
                  </Parallax>

                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className="mobile-only">
                      Simply Not your Average studio
                    </p>
                  </Parallax>
                </figure>
              </section>

              <section className="Team-text">
                <article>
                  <span>Makook</span>
                  <p>
                    Makook is a a creative lab where multiple disciplines
                    collide. We want to make the world more acces­sible with
                    sus­tain­a­ble, func­tion­al and delightful experiences.
                    Grounding our work in an agile and col­labor­ative process,
                    paired with our deeply root­ed pas­sion for exp­loration and
                    know­ledge acqu­isit­ion, we create meaningful diffrence by
                    ap­ply­ing func­tion­al aes­the­tics to in­sight.
                  </p>

                  <div className="expertise">
                    <span>Expertise</span>
                    <h4 className="little-title ">PRODUCT</h4>
                    <h3>App Development / Web Development / UX-UI Design</h3>
                    <br />
                    <h4 className="little-title ">BRAND</h4>
                    <h3>
                      Brand Identities / Design Systems / Interaction Design /
                      Communication
                    </h3>
                    <br />
                    <h4 className="little-title ">VIDEO AND AUDIO</h4>
                    <h3>Cinematography/ Photography / Direction </h3>
                    <br />
                    <h4 className="little-title ">3D</h4>
                    <h3>3D Modeling/ Animation / Art Direction </h3>
                  </div>

                  <br />
                  <span>We woked with</span>
                  <p>
                    Across many years our team members crafted the best
                    expriences for the most recognisable national and
                    international brands like :
                  </p>
                  <h3>
                    Ooredoo / Djeezy / Mobilis / Faderco / Hikma / Eslewedy /
                    Octrino / Celia / Naftal / Condor / Oppo
                    <br />
                  </h3>
                </article>
              </section>

              <Footer title={"Contact"} />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default IndexPage;

export const Image = graphql`
  fragment Image on File {
    childImageSharp {
      fluid(maxWidth: 1400, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery2 = graphql`
  query {
    imageOne: file(relativePath: { eq: "Makook_Studio.jpg" }) {
      ...Image
    }
  }
`;
